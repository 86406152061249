import React from "react"
import Img from "gatsby-image"
import styled from "styled-components"
import { useStaticQuery, graphql } from "gatsby"
const Image = styled(props => <Img {...props}></Img>)`
  width: 100%;
  @media screen and (min-width: 768px) {
    width: 75%;
  }
`
const Associazioni = props => {
  const { width } = { ...props }
  const data = useStaticQuery(graphql`
    query {
      placeholderImage: file(relativePath: { eq: "Associazioni4.png" }) {
        childImageSharp {
          fluid(maxWidth: 700) {
            ...GatsbyImageSharpFluid
            presentationWidth
          }
        }
      }
    }
  `)
  return (
    <Image
      style={{ maxWidth: width, margin: `0 auto` }}
      fluid={data.placeholderImage.childImageSharp.fluid}
    />
  )
}

export default Associazioni
