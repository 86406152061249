import React from "react"
import styled from "styled-components"
// import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Video from "../components/Video"
import Destinatari from "../components/Destinatari"
import ChiSiamo from "../components/ChiSiamo"
import Muffin from "../components/Images/Muffin"
import Emma from "../components/Images/Emma"

const Section = styled.div`
  background-color: ${props => props.backgroundColor};
  padding: 1.5em 0;
  @media screen and (min-width: 768px) {
    padding: 1.5em;
  }
  h3 {
    color: ${props =>
      props.backgroundColor === `#ffeb98` ? `#ec626b` : `#ffeb98`};
    margin-top: 1.5rem;
  }
  @media screen and (min-width: 768px) {
    h3 {
      margin-top: 0.5rem;
    }
  }
`

const DiscoverPage = () => (
  <Layout>
    <SEO title="Scopri" />
    <Section backgroundColor={`#ffeb98`} style={{ height: `50px` }} />
    <Section backgroundColor={`#ec626b`}>
      <Muffin />
      <Video />
      <Emma />
    </Section>
    <Section backgroundColor={`#ffeb98`}>
      <Destinatari />
    </Section>
    <div style={{ backgroundColor: `#ec626b`, height: `50px` }}></div>
    <Section backgroundColor={`#ffeb98`}>
      <ChiSiamo />
    </Section>
  </Layout>
)

export default DiscoverPage
