import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"

const StyledBtn = styled(props => <Link {...props} />)`
  background-color: #000000;
  box-shadow: 0px 4px 9px rgba(50, 50, 93, 0.4);
  color: #ffffff;
  display: inline-block;
  font-weight: bold;
  margin: 0.35rem ${props => (props.center ? `auto` : ``)};
  padding: 0.35rem;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
  transition: all 0.5s;
  width: 200px;
  &:hover {
    background-color: #262626;
    color: #ffffff;
    text-decoration: none;
  }
`

export default StyledBtn
