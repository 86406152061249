import React from "react"
import Img from "gatsby-image"
import { useStaticQuery, graphql } from "gatsby"
import MascotContainer from "./Mascots"

const Emma = props => {
  const { top, skew } = { ...props }
  const data = useStaticQuery(graphql`
    query {
      placeholderImage: file(relativePath: { eq: "emma.png" }) {
        childImageSharp {
          fixed(width: 200) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `)
  return (
    <MascotContainer id="emma">
      <Img
        style={{
          filter: `url(#drop-shadow)`,
          position: `absolute`,
          top: top ? top : `200px`,
          right: `0`,
          transform: skew ? `skew(0deg, 5deg)` : ``,
        }}
        fixed={data.placeholderImage.childImageSharp.fixed}
      />
    </MascotContainer>
  )
}

export default Emma
