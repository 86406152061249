import React from "react"
import styled from "styled-components"

const Mascot = styled.div`
  @media screen and (max-width: 630px) {
    display: none !important;
  }
`

const MascotContainer = ({ id, children }) => (
  <Mascot>
    <svg height="0" xmlns="http://www.w3.org/2000/svg">
      <filter id={`drop-shadow-${id}`}>
        <feGaussianBlur in="SourceAlpha" stdDeviation="4" />
        <feOffset dx="12" dy="12" result="offsetblur" />
        <feFlood floodColor="rgba(0,0,0,0.5)" />
        <feComposite in2="offsetblur" operator="in" />
        <feMerge>
          <feMergeNode />
          <feMergeNode in="SourceGraphic" />
        </feMerge>
      </filter>
    </svg>
    {children}
  </Mascot>
)

export default MascotContainer
