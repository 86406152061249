import React from "react"
import styled from "styled-components"
import Associazioni from "./Images/Associazioni"
import Anteprima1 from "./Images/Anteprima1"
import StyledBtn from "../components/StyledBtn"

const Section = styled.div`
  margin: 0 auto;
  padding: 1em;
  ul {
    list-style: none;
  }
`
const Row = styled.div`
  display: flex;
  flex-direction: column-reverse;
  @media screen and (min-width: 768px) {
    justify-content: space-between;
    flex-direction: row;
  }
`

const Col = styled.div`
  flex: 1;
  @media screen and (min-width: 768px) {
    padding: 2.5rem;
  }
`

const Destinatari = () => (
  <Section>
    <Row>
      <Col>
        <h3>A chi verrà devoluto il ricavato?</h3>
        <p>
          Tutto il ricavato raccolto verrà devoluto ad alcune associazioni Onlus
          che gestiscono alcuni rifugi e gattili. Servirà quindi a finanziare le
          emergenze che i volontari si trovano ad affrontare ogni giorno, oltre
          che le sterilizzazioni delle colonie e il mantenimento dei randagi e
          delle numerosissime creaturine in cerca di casa e famiglia!
        </p>
        <p>Le associazioni nel progetto sono le seguenti:</p>
        <ul>
          <li>
            <strong>Associazione Progetto Miao-Fido Onlus</strong> (Trecate, NO)
          </li>
          <li>
            <strong>Associazione La Tana delle Tigri</strong> (Bareggio, MI)
          </li>
          <li>
            <strong>Associazione Amici dei Mici</strong> (Corbetta, MI)
          </li>
          <li>
            <strong>Gattosamente Miao</strong> (Bareggio, MI)
          </li>
        </ul>
      </Col>

      <Col
        style={{
          display: `flex`,
          flexDirection: `column`,
          alignItems: `center`,
          justifyContent: `center`,
          margin: `-1.5rem`,
        }}
      >
        <Anteprima1 width="500px" />
        <div style={{ width: `100%`, padding: `0 1.5rem` }}>
          <Associazioni width="500px" />
        </div>
      </Col>
    </Row>
    <Row>
      <StyledBtn to="/acquista" center="true">
        Acquista
      </StyledBtn>
    </Row>
  </Section>
)

export default Destinatari
