import React from "react"
import styled from "styled-components"
import Anteprima2 from "./Images/Anteprima2"
import Anteprima3 from "./Images/Anteprima3"
import StyledBtn from "../components/StyledBtn"

const Section = styled.div`
  margin: 0 auto;
  padding: 1em;
  ul {
    list-style: none;
  }
`
const Row = styled.div`
  display: flex;
  flex-direction: column-reverse;
  @media screen and (min-width: 768px) {
    justify-content: space-between;
    flex-direction: row;
  }
`

const Col = styled.div`
  flex: 1;
  margin-top: 1rem;
  @media screen and (min-width: 768px) {
    padding: 0.5rem;
  }
`

const ChiSiamo = () => (
  <Section>
    <Row>
      <Col>
        <h3>Chi siamo?</h3>
        <p>
          La Gattivista è la risposta alla domanda: "come fare volontariato con
          il poco tempo e i mezzi che ho a disposizione?" Ecco perché vari
          autori danno la zampa al progetto: poter dare una mano anche così,
          facendo quello che si ama, con il poco tempo che si ha.
        </p>
        <p>
          Curatrice del progetto è Irene di Nello, in arte Irwing Phan, mentre
          gli autori partecipanti sono:
        </p>

        <ul>
          <li>
            Marga Biazzi, in arte <em>Blackbanshe</em>
          </li>
          <li>
            Marco Ventura e il suo <em>Team Norby</em>
          </li>
          <li>
            Filomena Nostrale, in arte <em>Ketsu</em>
          </li>
          <li>
            Giorgia Sulpizi, in arte <em>Chokorin</em>
          </li>
          <li>
            Irene di Nello, in arte <em>Irwing Phan</em>
          </li>
          <li>
            Francesca Sabbatini, <em>Basta una Matita</em>
          </li>
          <li>
            Maria Antonella Boccotti, in arte <em>La Regina Mab</em>
          </li>
          <li>
            Natascia Quirico, in arte <em>Isis Art</em>
          </li>
          <li>
            Sara Porello, in arte <em>Sayuri</em>
          </li>
          <li>
            Cinzia Colato, in arte <em>Sanguesmunto</em>
          </li>
          <li>Ivana Murianni (editing)</li>
        </ul>
      </Col>
      <Col
        style={{
          display: `flex`,
          flexDirection: `column`,
          alignItems: `center`,
          justifyContent: `space-around`,
          margin: `-1.5rem`,
        }}
      >
        <Anteprima2 width="500px" />
        <Anteprima3 width="500px" />
      </Col>
    </Row>
    <Row>
      <StyledBtn to="/acquista" center="true">
        Acquista
      </StyledBtn>
    </Row>
  </Section>
)

export default ChiSiamo
